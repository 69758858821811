<template>
    <div class="orgcontainer">
        <div class="banner">
            <div class="title">
                    <h1> درباره ما </h1>
                    <span class="titlebody"> برای ارتباط با تیم صرافی از راه های زیر در ارتباط باشید </span>
            </div>
            <div class="texts">
                <!-- <p class="explain">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p> -->
                <p class="explain">
                    {{faq[1].answer}}
                </p>
            <img class="aboutbanner" src="../../assets/aboutUsBanner.png" alt="">
            </div>
        </div>
        <div class="cards">
            <div v-for="(item,index) in faq" :key="index" class="card">
                <img src="../../assets/icons/cardsvg.svg" alt="">
                <div class="text">
                    <div class="title"> {{item.question}} </div>
                    <div class="body"> {{item.answer.slice(0,120)}} </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'AboutUs',
    data() {
        return {
            faq:[],
        }
    },
    methods: {
        async getFaq(){
            const res = await this.$axios.get('/faq?language=PERSIAN')
            this.faq=res.splice(2,4)
            console.log(this.faq) ;

        },
    },
    mounted() {
        this.getFaq();
    },
}
</script>

<style lang="scss" scoped>
.cards{
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap:12px ;
    justify-content: center;
    z-index: 5;
    .card{
        max-width: 400px;
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: white;
        border-radius: 12px;
        padding: 24px;
        column-gap: 12px;
        img{
            align-self: flex-start;
        }
        .text{
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            .title{
                font-size: clamp(14px,2vw,18px);
            }
            .body{
                font-size: clamp(12px,2vw,14px);
            }
        }
    }
}
.banner{
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    row-gap: 70px;
    .explain{
        color: grey;
        width: 50%;
    }
    .texts{
       display: flex;
       flex-direction: row;
       align-items: center; 
       justify-content: space-between;
        width: 100%;
    }
    .title{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .titlebody{
            font-size: clamp(16px,2vw,20px);
            color: grey;
        }
    }
}
.orgcontainer{
     &::before{
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: url('../../assets/feeBG.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 70px;
    h2{
        z-index: 5;
        align-self: center;
    }
    
}
@media only screen and(max-width:1000px){
    .orgcontainer{
        padding: 20px 30px ;
    }
    .cards{
        flex-direction: column;
        align-items: center;
        row-gap: 32px;
        .card{
            width: 100%;
        }
    }
    .texts{
        flex-direction: column !important;
    }
    .explain{
        width: 100% !important;
    }
    .aboutbanner{
        width: 100%;
    }
}
</style>